import React, { Fragment, useEffect } from 'react';
import { TextInput, SelectInput, BooleanInput, RadioButtonGroupInput, required } from 'react-admin';
import { FormLabel, makeStyles } from '@material-ui/core';
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux';

const styles = {
    flexContainer: { width: '100%' },
    inputField: { width: '48%' },
    row: { display: 'flex', justifyContent: 'space-between', marginBottom: 40, width: '100%' },
    flexLeftAccess: { width: "49%", marginRight: '3%', marginBottom: 55 },
    flexRightAccess: { width: "48%", paddingTop: '7px' },
    statusLabel: { paddingBottom: '16px', lineHeight: 0, color: '#607d8b' },
    booleanLabel: { padding: '0 12px 22px 0', color: 'rgba(0, 0, 0, 0.87)' }
};

const useStyles = makeStyles(styles);

const DoorsEditBasicDetailsTab = ({ handleDetailsValidation, labelColor }) => {
    const classes = useStyles();

    const formState = useFormState();

    const permissions = useSelector(state =>
        state.permissions
    );

    const setDetailsError =
        (!formState.valid && formState.submitFailed && (formState.errors.name || formState.errors.lockType))
            ? true : false;

    useEffect(() => {
        if (setDetailsError) {
            handleDetailsValidation('red');
        } else if (formState.valid || !(formState.errors.name || formState.errors.lockType)) {
            handleDetailsValidation(labelColor);
        }
    }, [formState, handleDetailsValidation, labelColor, setDetailsError]);

    return (
        <Fragment>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <TextInput
                        source="name"
                        fullWidth={true}
                        className={classes.inputField}
                        variant="standard"
                        validate={required()}
                    />
                    <SelectInput
                        source="lockType"
                        fullWidth={true}
                        className={classes.inputField}
                        variant="standard"
                        choices={[
                            { id: 3, name: 'Bikely BLE Lock' },
                            { id: 2, name: 'Bikely NarrowBand Lock' },
                            { id: 1, name: 'Plc' }
                        ]}
                        validate={required()}
                        disabled={permissions !== 'admin'}
                    />
                </div>
                <div className={classes.row}>
                    <div className={classes.flexLeftAccess}>
                        <FormLabel component="legend" style={{ color: '#607d8b' }}>Type</FormLabel>
                        <RadioButtonGroupInput
                            source="doorType"
                            label=""
                            choices={[
                                { id: 3, name: 'Family' },
                                { id: 2, name: 'Long tailed' },
                                { id: 1, name: 'Standard' },
                                { id: 0, name: 'Cargo' }
                            ]}
                            options={{
                                row: true
                            }}
                            defaultValue={1}
                            disabled={permissions !== 'admin'}
                        />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FormLabel component="legend" className={classes.booleanLabel}>Has charger</FormLabel>
                            <BooleanInput source="hasCharger" label="" />
                        </div>
                    </div>
                    <div className={classes.flexRightAccess}>
                        <FormLabel component="legend" className={classes.statusLabel}>Status</FormLabel>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FormLabel component="legend" className={classes.booleanLabel}>Enabled</FormLabel>
                            <BooleanInput source="status" label="" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default DoorsEditBasicDetailsTab;