import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink } from 'react-admin';
import LocationIcon from '@material-ui/icons/LocationOnOutlined';
import OrdersIcon from '@material-ui/icons/DescriptionOutlined';
import UsersIcon from '@material-ui/icons/PeopleAltOutlined';
import UserGroupsIcon from '@material-ui/icons/LocalLibraryOutlined';
import ClientsIcon from '@material-ui/icons/BusinessCenterOutlined';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PieChartIcon from '@material-ui/icons/PieChartOutlined';

import SubMenu from '../SubMenu';

const ClientSidebar = ({
    open,
    dense,
    onMenuClick,
    menuItemColor,
    menuCityManagement,
    handleCityMenu,
}) => {
    const pathname = useSelector(state =>
        state.router.location.pathname
    );

    return (
        <div>
            <MenuItemLink
                to={`/client-info`}
                primaryText={'Client Info'}
                leftIcon={<ClientsIcon style={{ color: menuItemColor }} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                style={{ color: menuItemColor }}
                selected={pathname === '/client-info'}
            />
            <SubMenu
                handleToggle={() => handleCityMenu()}
                isOpen={menuCityManagement}
                sidebarIsOpen={open}
                name={'Locations'}
                icon={<LocationCityIcon style={{ color: menuItemColor }} />}
                dense={dense}
                style={{ color: menuItemColor }}
            >
                <MenuItemLink
                    to={`/locations`}
                    primaryText={'Locations'}
                    leftIcon={<LocationIcon style={{ color: menuItemColor }} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    selected={pathname === '/locations'}
                    style={{ color: menuItemColor }}
                />
                <MenuItemLink
                    to={`/orders`}
                    primaryText={'Orders'}
                    leftIcon={<OrdersIcon style={{ color: menuItemColor }} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    style={{ color: menuItemColor }}
                    selected={pathname === '/orders'}
                />
                <MenuItemLink
                    to={`/user-groups`}
                    primaryText={'Private Groups'}
                    leftIcon={<UserGroupsIcon style={{ color: menuItemColor }} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    style={{ color: menuItemColor }}
                    selected={pathname === '/user-groups'}
                />
            </SubMenu>
            {/* <MenuItemLink
                to={`/stats`}
                primaryText={'Statistics'}
                leftIcon={<PieChartIcon style={{ color: menuItemColor }} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                style={{ color: menuItemColor }}
                selected={pathname === '/stats'}
            /> */}
            <MenuItemLink
                to={`/users`}
                primaryText={'Users'}
                leftIcon={<UsersIcon style={{ color: menuItemColor }} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                style={{ color: menuItemColor }}
                selected={pathname === '/users'}
            />
        </div>
    );
};

export default ClientSidebar;
