import React, { Fragment, useEffect, useState } from 'react';
import { useNotify, refreshView, Pagination } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config/config';
import CustomButton from '../shared/CustomButton';
import classnames from 'classnames';
import {
    Dialog, Tooltip, IconButton,
    makeStyles, Paper, Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow, TextField, Button, DialogActions,
    DialogTitle, DialogContent, DialogContentText,
    FormControlLabel, Switch
} from "@material-ui/core";
import StatusChangeDialog from '../locations/StatusChangeDialog';

import LockOpenIcon from '@material-ui/icons/LockOpen';

const useStyles = makeStyles({
    completed: { color: '#80C783' },
    pending: { color: '#5EBDE9' },
    ordered: { color: '#58BBB1' },
    canceled: { color: '#db574d' },
    paid: { color: '#58bbb1' },
    search: { display: 'flex', width: '190px', justifyContent: 'flex-end' },
    customerField: { width: '12%' },
    completeOrderField: { width: '15%' },
    button: { backgroundColor: '#72C948', boxShadow: 'none', '&:hover': { backgroundColor: '#72C948', opacity: .9, boxShadow: 'none' } },
})


const CompleteOrder = ({ record = {} }) => {
    const notify = useNotify();
    const dispatch = useDispatch();

    const { id, status } = record;

    const completeOrder = (e) => {
        e.stopPropagation();

        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Order/ChangeOrderStatusToCompleted?orderId=${id}`;

        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    dispatch(refreshView());
                    return notify('Order completed');
                }
            })
            .catch(error => {
                notify(error.message, 'warning');
            });
    };

    return (
        <Fragment>
            {!(status === 2 || status === 3) && (
                <CustomButton
                    label="Complete order"
                    type="primary"
                    onClick={completeOrder}
                    style={{ width: 'auto', height: 26, lineHeight: 1 }}
                />
            )}
        </Fragment>
    );
};

const ReactiveOrder = ({ record = {} }) => {
    const notify = useNotify();
    const dispatch = useDispatch();

    const { id, status } = record;

    const reactiveOrder = (e) => {
        e.stopPropagation();

        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Order/ChangeOrderStatusToOrderd?orderId=${id}`;

        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    dispatch(refreshView());
                    return notify('Order reactivated');
                }
            })
            .catch(error => {
                notify(error.message, 'warning');
            });
    };

    return (
        <>
            {(status === 2 || status === 3) && (
                <CustomButton
                    label="Reactive order"
                    type="primary"
                    onClick={reactiveOrder}
                    style={{ width: 'auto', height: 26, lineHeight: 0.5 }}
                />
            )}
        </>
    );
};

const OrderStatusField = ({ record = {} }) => {
    const classes = useStyles();

    const statusValues = {
        0: 'Pending',
        1: 'Ordered',
        2: 'Completed',
        3: 'Cancelled',
        4: 'Wait device to complete order'
    };

    const { status } = record;
    const orderStatus = statusValues[status];

    return (
        <span
            className={classnames({
                [classes.pending]: status === 0, // pending
                [classes.ordered]: status === 1, // ordered
                [classes.completed]: status === 2 || status === 4, // completed
                [classes.canceled]: status === 3 // canceled
            })}
        >
            {orderStatus}
        </span>
    );
};

const PaymentStatusField = props => {
    const classes = useStyles();
    const statusValues = {
        0: 'Not Paid',
        1: 'Paid',
        2: 'Refunded',
        3: 'PartiallyRefunded',
        4: 'Locked'
    };

    try {
        const { paymentStatus } = props.record;
        const orderPaymentStatus = statusValues[paymentStatus];


        return (
            <span
                className={classnames({
                    [classes.canceled]: paymentStatus === 0, // not paid
                    [classes.paid]: paymentStatus === 1, // paid
                    [classes.pending]: paymentStatus === 2, // refunded
                    [classes.ordered]: paymentStatus === 3 // partially refunded
                })}
            >
                {orderPaymentStatus}
            </span>
        );
    } catch (e) {
        return (
            <span
                className={classnames({
                    [classes.canceled]: true, // not paid
                })}
            >
                No Payment Status
            </span>
        );
    }
};

const LocationDoorField = ({ record }) => (
    <span>{record?.location?.name} / {record?.door?.name}</span>
);

const getDoorOrderStatus = (order) => {
    if(order.door.currentOrderId === 0) {
        return 'Locker is empty';
    }
    return `In order by ${order.door.currentOrderId === order.id ? 'this': 'other'} user`
}

const SupportOrderList = props => {
    const classes = useStyles();
    const notify = useNotify();

    const permissions = useSelector(state => state.permissions);
    const hasAccess = (permissions === 'admin');

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);
    const [total, setTotal] = useState(0);
    const [userIdSearch, setUserIdSearch] = useState('')
    const [orderIdSearch, setOrderIdSearch] = useState('')

    const [orders, setOrders] = useState([])
    const [promptOpen, setPromptOpen] = useState(false);
    const [doorId, setDoorId] = useState(null);


    const StatusSwitch = ({ record }) => {
        const { id, status, description } = record;
        const [active, setActive] = useState(status);
        const [dialogOpen, setDialogOpen] = useState(false);

        const handleStatusChange = () => {
            const token = localStorage.getItem('access_token');
            const endpoint = `${config.apiBaseUrl}/Door/Activate`;

            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id, description, isActive: !active })
            });

            console.log({ id, description, isActive: !active });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    if (response.success) {
                        setActive(!active)
                        notify('Status successfully changed', 'success');
                    } else {
                        notify(response.error.message, 'error');
                    }
                    setDialogOpen(false);
                });
        };

        return (
            <>
                <FormControlLabel
                    className={classes.statusSwitch}
                    control={
                        <Switch
                            checked={active}
                            onChange={(e, val) => setDialogOpen(true)}
                            color="primary"
                        />
                    }
                    label={active ? 'Enabled' : 'Disabled'}
                />
                <StatusChangeDialog
                    open={dialogOpen}
                    title='Are you sure you want to change door status?'
                    setOpen={(val) => setDialogOpen(val)}
                    onChangeStatus={handleStatusChange}
                />
            </>
        )
    };

    const onUnlockDoor = (id) => {
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Door/AdminUnlock?id=${id}`;
        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                const result = response.result;
                if (result?.success) {
                    return notify('Door unlocked', 'success');
                } else {
                    notify(`Error: ${response.error.message}`, 'warning');
                }
            })
            .catch(error => {
                notify(`Error: ${error.message}`, 'warning');
            });
    };

    const AlertDialog = ({ isOpen }) => {
        const handleClose = (id) => {
            if (typeof id === 'number') {
                onUnlockDoor(id);
                setDoorId(null);
            }
            setPromptOpen(false);
        };

        return (
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Unlock the door"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to unlock this door?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} name="cancel" color="secondary">
                        Cancel
                    </Button>
                    <Button name="confirm" onClick={() => handleClose(doorId)} color="primary">
                        Unlock
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const searchUser = (userId, orderId) => {
        if (!userId || userId === '') {
            userId = 0;
        }
        if (!orderId || orderId === '') {
            orderId = 0;
        }

        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Order/SearchUserOrder?orderId=${orderId}&userId=${userId}&page=${page}&perPage=${perPage}`;

        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    var _res = response.result;
                    setTotal(_res.totalCount);
                    setOrders(_res.items);
                    console.log(_res.items)
                }
            })
            .catch(error => {
                // notify(error.message, 'warning');
            });
    }

    useEffect(() => {
        if (userIdSearch !== '' || orderIdSearch !== '') {
            searchUser(userIdSearch, orderIdSearch)
        }
    }, [])

    

    return (
        <>
            <AlertDialog isOpen={promptOpen} />
            <div style={{ display: 'flex', gap: '10px' }}>
                <TextField
                    id="user_search"
                    label="User Id"
                    variant="outlined"
                    className={classes.search}
                    onChange={(e) => {
                        setUserIdSearch(e.target.value)
                    }}
                />
                <TextField
                    id="order_search"
                    label="Order Id"
                    variant="outlined"
                    className={classes.search}
                    onChange={(e) => {
                        setOrderIdSearch(e.target.value)
                    }}
                />
                <Button className={classes.button} variant="contained" color="primary" onClick={() => {
                    console.log(userIdSearch, orderIdSearch)
                    if (userIdSearch !== '' || orderIdSearch !== '') {
                        searchUser(userIdSearch, orderIdSearch)
                    }
                }}>Search</Button>

            </div>
            <TableContainer component={Paper}>
                <Table size="small" className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>User ID</TableCell>
                            <TableCell>OrderID</TableCell>
                            <TableCell>From</TableCell>
                            <TableCell>To</TableCell>
                            <TableCell>Order Status</TableCell>
                            <TableCell>Door Enabled</TableCell>
                            <TableCell>Location/Door</TableCell>
                            <TableCell>Door/Order status</TableCell>
                            <TableCell>Unlock door</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map(_order => (
                            <TableRow key={_order.id}>
                                <TableCell component="th" scope="row">{_order.userId}</TableCell>
                                <TableCell component="th" scope="row">{_order.id}</TableCell>
                                <TableCell>{(new Date(_order.usedFrom)).toLocaleString()}</TableCell>
                                <TableCell>{_order.usedTo !== null ? (new Date(_order.usedTo)).toLocaleString() : ''}</TableCell>
                                <TableCell><OrderStatusField record={_order} /></TableCell>
                                <TableCell><StatusSwitch record={_order.door} /></TableCell>
                                <TableCell><LocationDoorField record={_order} /></TableCell>
                                <TableCell>{getDoorOrderStatus(_order)}</TableCell>
                                <TableCell align="left">
                                    <Tooltip title="Unlock door">
                                        <IconButton>
                                            <LockOpenIcon style={{ color: 'rgba(51, 51, 52, 0.87)', cursor: 'pointer' }} onClick={() => { setPromptOpen(true); setDoorId(_order.door.id) }} />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>

                                <TableCell>
                                    <ReactiveOrder record={_order} />
                                    <CompleteOrder record={_order} label="" headerClassName={classes.completeOrderField} />
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                page={page}
                perPage={perPage}
                setPage={setPage}
                setPerPage={setPerPage}
                total={total}
            />
        </>
    );
};

export default SupportOrderList;