import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink } from 'react-admin';
import UsersIcon from '@material-ui/icons/PeopleAltOutlined';

const SupportSidebar = ({
    open,
    dense,
    onMenuClick,
    menuItemColor,

}) => {
    const pathname = useSelector(state =>
        state.router.location.pathname
    );

    return (
        <div>
            <MenuItemLink
                to={`/support`}
                primaryText={'Support'}
                leftIcon={<UsersIcon style={{ color: menuItemColor }} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                style={{ color: menuItemColor }}
                selected={pathname === '/support'}
            />
        </div>
    );
};

export default SupportSidebar;
