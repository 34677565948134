import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DashboardMenuItem, MenuItemLink } from 'react-admin';

import SettingsIcon from '@material-ui/icons/Settings';
import { useMediaQuery } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import { darkTheme, lightTheme } from "./themes";

import AdminSidebar from './sidebar/Admin';
import ClientSidebar from './sidebar/Client';
import SupportSidebar from './sidebar/Support'

const Menu = ({ onMenuClick, dense, logout }) => {
    const [menuCityManagement, setMenuCityManagement] = useState(false); // (Local state)

    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    useSelector(state => state.theme); // force rerender on theme change (Redux state)
    const permissions = useSelector(state =>
        state.permissions
    );

    const pathname = useSelector(state =>
        state.router.location.pathname
    );

    const handleCityMenu = () => {
        setMenuCityManagement(!menuCityManagement);
    };

    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme : lightTheme
    );
    const menuItemColor = theme.palette.menuItemsColor;

    return (
        <div>

            {permissions === 'admin' && (
                <>
                    <DashboardMenuItem
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        leftIcon={<DashboardIcon style={{ color: menuItemColor }} />}
                        style={{ color: menuItemColor }}
                        selected={pathname === '/'}
                    />

                    <AdminSidebar
                        open={open}
                        dense={dense}
                        onMenuClick={onMenuClick}
                        menuItemColor={menuItemColor}
                        menuCityManagement={menuCityManagement}
                        handleCityMenu={handleCityMenu}
                    /></>
            )}

            {permissions === 'client' && (
                <>
                    <DashboardMenuItem
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        leftIcon={<DashboardIcon style={{ color: menuItemColor }} />}
                        style={{ color: menuItemColor }}
                        selected={pathname === '/'}
                    />
                    <ClientSidebar
                        open={open}
                        dense={dense}
                        onMenuClick={onMenuClick}
                        menuItemColor={menuItemColor}
                        handleCityMenu={handleCityMenu}
                        menuCityManagement={menuCityManagement}
                    /></>
            )}

            {permissions === 'support' && (
                <>
                    <SupportSidebar
                        open={open}
                        dense={dense}
                        onMenuClick={onMenuClick}
                        menuItemColor={menuItemColor}
                        handleCityMenu={handleCityMenu}
                        menuCityManagement={menuCityManagement}
                    />
                </>
            )}

            {isXsmall && (
                <MenuItemLink
                    to="/settings"
                    primaryText={'Settings'}
                    leftIcon={<SettingsIcon style={{ color: menuItemColor }} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    style={{ color: menuItemColor }}
                    selected={pathname === '/settings'}
                />
            )}
            {isXsmall && logout}
        </div>
    );
};

export default Menu;
