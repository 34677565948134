import React, { Fragment, useState } from 'react';
import { TextInput, required, NumberInput, SelectInput, useQuery } from 'react-admin';

import { makeStyles } from "@material-ui/core";

const styles = {
    flexContainer: { width: '100%' },
    inputField: { width: '48%' },
    row: { display: 'flex', justifyContent: 'space-between', marginBottom: 40, width: '100%', flexWrap: 'wrap' },
    label: { marginBottom: 15, marginTop: 25, display: "block" }
};

const useStyles = makeStyles(styles);

const ClientsDetailsTab = props => {
    const classes = useStyles();
    const [countries, setCountries] = useState([]);

    useQuery(
        {
            type: 'GET_LIST',
            resource: 'country',
            payload: {
                pagination: {
                    page: 1,
                    perPage: 100
                },
                sort: {
                    field: 'id',
                    order: 'ASC'
                },
                filter: {}
            }
        },
        {
            onSuccess: ({ data }) => {
                setCountries(data);
            }
        }
    );

    return (
        <Fragment>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <TextInput
                        source="name"
                        fullWidth={true}
                        className={classes.inputField}
                        variant="standard"
                        validate={required()}
                    />
                    <NumberInput
                        source="organizationNumber"
                        className={classes.inputField}
                        variant="standard"
                        fullWidth={true}
                        validate={required()}
                    />
                    <NumberInput
                        source="accountNumber"
                        className={classes.inputField}
                        label="Account Number / IBAN"
                        variant="standard"
                        fullWidth={true}
                    />
                    <TextInput
                        source="organizationEmail"
                        fullWidth={true}
                        type="email"
                        className={classes.inputField}
                        variant="standard"
                        validate={required()}
                    />
                    <TextInput
                        source="bicSwift"
                        fullWidth={true}
                        label="BIC / SWIFT"
                        className={classes.inputField}
                        variant="standard"
                    />
                </div>
                <label className={classes.label}>Address</label>
                <div className={classes.row}>
                    <SelectInput
                        label="Country"
                        source="countryId"
                        choices={countries}
                        variant="standard"
                        fullWidth={true}
                        className={classes.inputField}
                        validate={required()}
                    />
                    <SelectInput
                        label="Address Type"
                        source="addressType"
                        choices={[
                            { id: 0, name: 'Residental' },
                            { id: 1, name: 'Temporary' }
                        ]}
                        variant="standard"
                        className={classes.inputField}
                        fullWidth={true}
                    />
                    <TextInput
                        label="Street"
                        source="street"
                        variant="standard"
                        className={classes.inputField}
                    />
                    <NumberInput
                        label="Post code"
                        source="postCode"
                        variant="standard"
                        className={classes.inputField}
                    />
                    <TextInput
                        label="Post Name"
                        source="postName"
                        variant="standard"
                        className={classes.inputField}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default ClientsDetailsTab;