import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { List, Datagrid, TextField, SimpleForm, SaveButton, DeleteWithConfirmButton, EditButton, useCreate, useNotify, refreshView } from 'react-admin';
import DoorsBasicDetailsTab from './doors/DoorsBasicDetailsTab';
import DoorsLockDetailsTab from './doors/DoorsLockDetailsTab';
import CustomButton from '../../shared/CustomButton';
import CustomLabel from '../../shared/CustomLabel';
import config from '../../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, useFormState } from 'react-final-form';
import {
    Dialog, Tabs, Tab, makeStyles, Snackbar, Tooltip, DialogActions, DialogTitle,
    DialogContent, Button, DialogContentText, IconButton, Select, MenuItem
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { darkTheme, lightTheme } from "../../layout/themes";
import LockOpenIcon from '@material-ui/icons/LockOpen';

import momentz from 'moment-timezone';
import moment from 'moment';
import StatusChangeDialog from '../StatusChangeDialog';

const useStyles = makeStyles({
    create: { marginTop: '20px', marginBottom: '20px', width: '100%', display: 'flex', justifyContent: 'flex-start' },
    dialogPaper: { minHeight: '75vh', borderRadius: 0, padding: '10px 10px 0' },
    toolbar: {
        display: 'flex', justifyContent: 'flex-end', paddingTop: '20px', borderTop: '1px solid #d7d8d8',
        '& button': {
            marginTop: 'auto !important',
            marginBottom: '20px !important'
        }
    },
    statusSwitch: {
        '& .MuiTypography-body1': { fontSize: '0.875rem' }
    },
    statusField: { display: 'flex', alignItems: 'center', width: 'max-content' },
    deviceStatus: { borderRadius: '50%', width: '26px', height: '26px' },
    signalField: { height: '28px', width: '106px', textAlign: 'center', borderRadius: '4px', lineHeight: '2' }
});

const CustomSaveButton = ({ onClose, onNext, onPrevious, checkValidation, handleChange, clickedTab, lockDetailsError,
    setErrorMessage, handleLockDetailsError, ...props }) => {

    const classes = useStyles();

    const form = useForm();
    const formState = useFormState();

    const { valid } = formState;

    const id = props.id;
    const activeTab = props.activeTab;

    const dispatch = useDispatch();
    const notify = useNotify();

    const [create] = useCreate('doors');

    useEffect(() => {
        if (!valid) {
            setErrorMessage();
            return form.submit();
        }

        if (checkValidation) {
            handleChange(clickedTab);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkValidation]);

    const handleSave = useCallback(
        () => {
            if (!valid) {
                return setErrorMessage();
            }

            let formData = Object.assign({}, formState.values);
            if (formData.maxConcurrent === 0) formData.maxConcurrent = 1;
            delete formData.id;
            
            create(
                {
                    payload: { data: { ...formData, locationId: id } },
                },
                {
                    onSuccess: (result) => {
                        // set door pricing
                        // const { id } = result.data;
                        // saveDoorPrice(id);

                        dispatch(refreshView());
                        notify('New Door added to this location', 'info');
                    },
                    onFailure: (error) => notify(error.message, 'warning')
                }
            );
        },
        // eslint-disable-next-line
        [create, dispatch, formState, notify, id, setErrorMessage]
    );

    const handleNext = (e) => {
        e.preventDefault();
        if (!valid) {
            setErrorMessage();
            return form.submit();
        }
        onNext();
    };

    const goBack = (e) => {
        e.preventDefault();
        if (!valid) {
            setErrorMessage();
            return form.submit();
        }
        onPrevious();
    };

    const onCancel = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <Fragment>
            <Fragment>
                <div className={classes.toolbar}>
                    <CustomButton label="Cancel" type="default" onClick={onCancel} style={{ width: '104px', marginLeft: '20px', marginRight: 'auto' }} />
                    {activeTab !== 0 && (
                        <CustomButton type="default" label="Previous" onClick={goBack} style={{ marginLeft: '20px', marginRight: '20px' }} />
                    )}
                    {activeTab !== 1 && (
                        <CustomButton label="Next" type="secondary" onClick={handleNext} style={{ width: '104px', marginLeft: '20px', marginRight: '20px' }} />
                    )}
                    {activeTab === 1 && (
                        <SaveButton
                            handleSubmitWithRedirect={handleSave}
                            style={{ marginLeft: '20px', marginBottom: '32px', marginRight: '20px' }}
                        />
                    )}
                </div>
            </Fragment>
        </Fragment>
    );
};

const DoorsCreateDialog = ({ onClose, ...props }) => {
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const [open, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [clickedTab, setClickedTab] = useState(0);
    const [checkValidation, setCheckValidation] = useState(0);
    const [lockDetailsError, setLockDetailsError] = useState(true);

    const tabs = [
        { id: 0, name: "Basic Details" },
        { id: 1, name: "Lock Details" },
    ];

    const onChangeTab = (event, value) => {
        setClickedTab(value);
        setCheckValidation(checkValidation + 1);
    };

    const handleChange = (value) => {
        if (activeTab === 1) setLockDetailsError(false);
        setActiveTab(value);
    };

    const handleNext = (event, value) => {
        if (activeTab === 1) setLockDetailsError(false);
        setActiveTab(activeTab + 1);
    };

    const handlePrevious = (event, value) => {
        if (activeTab === 1) setLockDetailsError(false);
        setActiveTab(activeTab - 1);
    };

    const setErrorMessage = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleLockDetailsError = (value) => {
        setLockDetailsError(value);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} style={{ backgroundColor: '#e57373' }} />;
    }

    return (
        <Fragment>
            <CloseIcon style={{ position: 'absolute', top: 15, right: 15, color: theme.secondary.main, cursor: 'pointer' }} onClick={() => onClose()} />
            <SimpleForm
                toolbar={
                    <CustomSaveButton
                        activeTab={activeTab}
                        onClose={onClose}
                        onNext={handleNext}
                        onPrevious={handlePrevious}
                        clickedTab={clickedTab}
                        checkValidation={checkValidation}
                        handleChange={handleChange}
                        setErrorMessage={setErrorMessage}
                        handleLockDetailsError={handleLockDetailsError}
                        lockDetailsError={lockDetailsError}
                        id={props.id}
                    />}>
                <CustomLabel title={'New door'} style={{ marginBottom: '20px', marginTop: 10 }} />
                <Tabs
                    fullWidth={true}
                    value={activeTab}
                    onChange={onChangeTab}
                    indicatorColor="primary"
                    style={{ borderBottom: '1px solid #e6e4e4' }}
                >
                    {tabs.map(choice => (
                        <Tab key={choice.id} label={choice.name} value={choice.id} style={{ color: '#4e6a77' }} />
                    ))}
                </Tabs>
                <div style={{ width: '700px', display: 'flex' }}>
                    {activeTab === 0 && (
                        <DoorsBasicDetailsTab style={{ marginTop: '15px' }} />
                    )}
                    {activeTab === 1 && (
                        <DoorsLockDetailsTab />
                    )}
                </div>
                <div>
                    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} >
                        <Alert onClose={handleClose} severity="error">
                            Form is not valid. Please check for errors
                        </Alert>
                    </Snackbar>
                </div>
            </SimpleForm>
        </Fragment>
    );
};

const DoorsCreateButton = (props) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleClickOpen = (e) => {
        e.preventDefault();
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };

    return (
        <Fragment>
            <CustomButton type="primary" style={{ marginBottom: '30px' }} fullWidth={true} label="Create New" onClick={handleClickOpen} />
            <Dialog open={open} onClose={handleClose} children={<DoorsCreateDialog id={props.id} onClose={handleClose} />} maxWidth={false} classes={{ paper: classes.dialogPaper }} />
        </Fragment>
    );
};

const LocationsEditDoorsTab = ({ permissions, viewAccess, ...props }) => {
    const history = useHistory();
    const notify = useNotify();
    const { id } = props.record;
    const [doorId, setDoorId] = useState(null);
    const [promptOpen, setPromptOpen] = useState(false);

    const onCancel = e => {
        e.preventDefault();
        history.push('/locations');
    };

    const onUnlockDoor = (id) => {
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Door/AdminUnlock?id=${id}`;
        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                const result = response.result;
                if (result?.success) {
                    return notify('Door unlocked', 'success');
                } else {
                    notify(`Error: ${response.error.message}`, 'warning');
                }
            })
            .catch(error => {
                notify(`Error: ${error.message}`, 'warning');
            });
    };

    const UnlockButton = ({ source, record = {} }) => {
        const { id } = record;

        return (
            <Tooltip title="Unlock door">
                <IconButton>
                    <LockOpenIcon style={{ color: 'rgba(51, 51, 52, 0.87)', cursor: 'pointer' }} onClick={() => { setPromptOpen(true); setDoorId(id); }} />
                </IconButton>
            </Tooltip>
        )
    };

    const AlertDialog = ({ isOpen }) => {
        const handleClose = (id) => {
            if (typeof id === 'number') {
                onUnlockDoor(id);
                setDoorId(null);
            }
            setPromptOpen(false);
        };

        return (
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Unlock the door"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to unlock this door?
                            </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} name="cancel" color="secondary">
                        Cancel
                    </Button>
                    <Button name="confirm" onClick={() => handleClose(doorId)} color="primary">
                        Unlock
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const DeviceStatus = ({ record = {} }) => {
        const classes = useStyles();

        const { lockType, deviceStatus, lastHeartBeat } = record;
        if (lockType === 1) return <span>-</span>; // don't show status if lockType is PLC

        const statusColor = {
            null: '#282828',
            'ONLINE': '#34bfa3',
            'OFFLINE': '#b9b9b9'
        };

        const timezone = momentz.tz.guess();
        const time = moment.tz(lastHeartBeat, timezone).format('MMMM Do YYYY, HH:mm:ss');

        return (
            <Fragment>
                <Tooltip title={`LAST HEARTBEAT ${time}`} placement="right">
                    <div className={classes.statusField}>
                        <div style={{ backgroundColor: `${statusColor[deviceStatus]}` }} className={classes.deviceStatus}></div>
                        <span style={{ marginLeft: '5px' }}>{!deviceStatus ? 'Unavailable' : deviceStatus}</span>
                    </div>
                </Tooltip>
            </Fragment>
        );
    };

    const SignalStrength = ({ record = {} }) => {
        const classes = useStyles();

        const { lockType, deviceSignalStrengh } = record;
        if (lockType === 1) return <span>-</span>; // don't show signal strength if lockType is PLC

        const statusColor = {
            'EXCELLENT': '#34bfa3',
            'FAIR': '#14c5dc',
            'POOR': '#db574d',
            'UNKNOWN': '#eaeaea'
        };

        return (
            <div style={{ backgroundColor: statusColor[deviceSignalStrengh], color: deviceSignalStrengh === 'UNKNOWN' ? '#5c5b61' : 'white' }} className={classes.signalField}>
                { deviceSignalStrengh}
            </div>
        );
    };

    const LocalAccessKey = ({ record = {} }) => {
        const { localAccessKeyName, localAccessKey, lockType } = record;

        if (lockType === 1) return <span>-</span>;

        return <span>{`${localAccessKeyName} - ${localAccessKey}`}</span>
    };

    const ActionDropdown = ({ record, ...props }) => {
        const useStyles = makeStyles({
            btn: { display: "block" }
        });
        const classes = useStyles();
        
        const DeleteConfirmationDialog = () => {
            const { name, locationId } = record;
            const title = `Delete door "${name}"`;
    
            return (
                <DeleteWithConfirmButton
                    record={record}
                    resource="doors"
                    confirmTitle={title}
                    redirect={`/locations/${locationId}/doors`}
                />
            );
        };

        return (
            <Fragment>
                <Select disableUnderline IconComponent={MoreVertIcon} >
                    <MenuItem><EditButton record={record} basePath="/doors" className={classes.btn} /></MenuItem>
                    <MenuItem><DeleteConfirmationDialog/></MenuItem>
                </Select>
            </Fragment>
        );
    }

    const StatusField = ({ record = {} }) => {
        const { status } = record;
        return(
            <span>
                {status ? 'Enabled' : 'Disabled'}
            </span>
        );
    };

    return (
        <Fragment>
            {permissions === 'admin' ? (
                <DoorsCreateButton id={id} />
            ) :
                (
                    <div style={{ marginTop: '30px' }}></div>
                )}
            <List filter={{ LocationId: id }} resource="doors" basePath="" actions={null} hasEdit={false} hasCreate={false} hasShow={true} hasList={true} bulkActionButtons={false}>
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <LocalAccessKey source="localAccessKey" label="Local Access Key" />
                    <StatusField source="status" />
                    <DeviceStatus source="lastHeartBeat" label="Device Status" sortable={false} />
                    <SignalStrength source="signal" label="Signal" sortable={false} />
                    <ActionDropdown label="Actions" />
                </Datagrid>
            </List>
            <CustomButton
                type="default"
                label={viewAccess ? 'Go Back' : 'Cancel'}
                onClick={onCancel}
                style={{ marginTop: 50, float: 'right' }}
            />
            <AlertDialog isOpen={promptOpen} />
        </Fragment>
    );
};

export default LocationsEditDoorsTab;