import { FormControlLabel, makeStyles, Switch } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, {useState } from 'react';
import { useNotify } from 'react-admin';
import config from '../../config/config';
import StatusChangeDialog from '../locations/StatusChangeDialog';


const useStyles = makeStyles({
    switch: {
        '& .MuiTypography-body1': { fontSize: '0.875rem' }
    }
});

const SuspendSwitch = ({ record }) => {
    const classes = useStyles();
    const notify = useNotify();
    const { id, isSuspended } = record;
    const [active, setActive] = useState(!isSuspended);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [reason, setReason] = useState('');

    const handleChange = (e, value) => {
        console.log(value);
        setDialogOpen(true);
    }

    const handleStatusChange = () => {
        setDialogOpen(false);
        setReason('');
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/User/SuspendUser`;
        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                userId: id,
                suspension: active,
                message: reason
            })
        });
        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    setActive(!active);
                    notify('Status successfully changed', 'success');
                } else {
                    notify(response.error.message, 'error');
                }
                setDialogOpen(false);
            });
    };

    return (
        <>
            <FormControlLabel
                className={classes.switch}
                control={
                    <Switch
                        checked={active}
                        onChange={(e, val) => handleChange(e, val)}
                        color="primary"
                    />
                }
                label={active ? 'Active' : 'Suspended'}
            />
            <StatusChangeDialog
                open={dialogOpen}
                title={`Are you sure you want to ${active ? 'suspend' : 'activate'} this user?`}
                setOpen={(val) => setDialogOpen(val)}
                onChangeStatus={handleStatusChange}
                primaryBtnLabel={active ? 'Suspend' : 'Activate'}
            >
                <TextField
                    label="Reason"
                    variant="outlined"
                    multiline
                    value={reason}
                    rows={4}
                    style={{ width: '100%', marginBottom: 20 }}
                    onChange={e => setReason(e.target.value)}
                />
            </StatusChangeDialog>
        </>
    )
};

export default SuspendSwitch;