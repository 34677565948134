import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { required, TextInput, SimpleForm } from 'react-admin';

const useStyles = makeStyles({
    dialogActions: { display: 'flex', justifyContent: 'flex-end', marginTop: 20 },
    dialog: { '& .MuiDialog-paperWidthSm': { margin: 0, width: 500, padding: 10 } },
    closeBtn: { position: 'absolute', right: 0, top: 0 },
    title: { marginTop: 20 },
    button: { minWidth: 100, height: 45 }
});

const StatusChangeDialog = ({ open, setOpen, onChangeStatus, title, primaryBtnLabel = 'Change', children }) => {
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    const handleStatusChange = () => {
        onChangeStatus();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.dialog}>
            <DialogTitle id="simple-dialog-title" className={classes.title}>
                {title}
                <IconButton onClick={handleClose} className={classes.closeBtn}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <SimpleForm toolbar={<div className={classes.dialogActions}>
                    <Button variant="outlined" color="secondary" className={classes.button} onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="primary" className={classes.button} style={{ marginLeft: 15, width: 130 }} onClick={handleStatusChange}>{primaryBtnLabel}</Button>
                </div>}>
                    <TextInput
                        label="Reason"
                        source="description"
                        fullWidth={true}
                        rows="4"
                        multiline
                        variant="outlined"
                        className={classes.inputField}
                        validate={required()}
                    />
                </SimpleForm>
            </DialogContent>
        </Dialog>
    )
};

export default StatusChangeDialog;